import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import './App.css';
import brand from './assets/images/p-b-transparent.png';

import Home from './Home';
import Feedback from './Feedback';
import Prototypes from './Prototypes';
import Contact from './Contact';

const Header = (props) => (
  <div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">

      <NavLink className="navbar-brand" to="/" exact activeClassName="active">
        <img src={brand} width="30" height="30" alt="logo" />
      </NavLink>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fa fa-bars"></i>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink className="nav-link" to="/prototypes" activeClassName="active">Prototypes</NavLink>
          </li>
          <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink className="nav-link" to="/feedback" activeClassName="active">Feedback</NavLink>
          </li>
          <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
            <NavLink className="nav-link" to="/contact" activeClassName="active">Contact</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  </div>
);

const Footer = (props) => (
  <div className="fixed-bottom navbar navbar-light bg-light">
    <span className="ml-auto">&copy; 2019 Secret Investments, LLC</span>
  </div>
);

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="container-fluid mt-2">
          <Route exact path="/" component={Home} />
          <Route path="/prototypes" render={() => <Prototypes />} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/contact" component={Contact} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
