import firebase from 'firebase'
// Initialize Firebase
var config = {
    apiKey: "AIzaSyCqGiI_yXz2LdidWTI7341fgvXrB4MzRx4",
    authDomain: "prettybrains-817e2.firebaseapp.com",
    databaseURL: "https://prettybrains-817e2.firebaseio.com",
    projectId: "prettybrains-817e2",
    storageBucket: "prettybrains-817e2.appspot.com",
    messagingSenderId: "372291232006"
  };
firebase.initializeApp(config);
export default firebase;
