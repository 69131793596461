import React, { Component } from 'react';
import './App.css';
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); return 1; });
  return images;
}

const images = importAll(require.context('./assets/images', false, /\.(png|jpe?g|svg)$/));


class Home extends Component {
  render() {
    return (
      <div>
        <div className="row align-items-center">
          <div className="col-12 col-sm-6 col-md-5 col-lg-4">
            <img src={images['pretty-brains-logo.png']} className="img-fluid" alt="logo" />
          </div>
          <div className="col-12 col-sm-6 col-md-7 col-lg-8 text-center">
            <div className="jumbotron mr-sm-5">
              <h1>Look Good.</h1>
              <h1>Build Smart.</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="jumbotron">
              <div className="row">
                <div className="col-12 col-md-4 mb-3">
                  <div className="card">
                    <img className="card-img-top" src={images['guess-the-card.jpg']} alt="Card cap" />
                    <div className="card-body">
                      <h5 className="card-title">Guess the Card</h5>
                      <p className="card-text">Fun new take on a classic game where you get to create your own heads-up cards!</p>
                      <a href="/" className="btn btn-primary">Coming Soon</a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card">
                    <img className="card-img-top" src={images['word-brain.jpg']} alt="Card cap" />
                    <div className="card-body">
                      <h5 className="card-title">Word Brain</h5>
                      <p className="card-text">Find all the hidden words! Fun way to train your brain and learn new words.</p>
                      <a href="/" className="btn btn-primary">Coming Soon</a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card">
                    <img className="card-img-top" src={images['raccoon-rescue.jpg']} alt="Card cap" />
                    <div className="card-body">
                      <h5 className="card-title">Raccoon Rescue</h5>
                      <p className="card-text">Great bubble shooter game! Help the Raccoon Mama save her babies from bubbles.</p>
                      <a href="/" className="btn btn-primary">Coming Soon</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
