import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './App.css';

class Prototypes extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <h2 className="pink m-3">Prototypes</h2>
        </div>
        <div className="col-12 col-sm-6">
          <div className="card">
            <h5 className="card-header">BetterDates</h5>
            <div className="card-body">
              <h5 className="card-title">No more boring dates. Ever.</h5>
              <p className="card-text">Make your dates fun, creative, and interactive. Even if they're not a match, you'll still have a great time! Guaranteed.</p>
              <Link replace className="btn btn-success d-block m-3" to="/prototypes/betterdates">
                Launch
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="card">
            <h5 className="card-header">Proofsheet</h5>
            <div className="card-body">
              <h5 className="card-title">Feedback on your pictures</h5>
              <p className="card-text">A simple way to find what people think are the best pictures</p>
              <Link replace className="btn btn-primary d-block m-3" to="/prototypes/headshot/play/new">
                Launch
              </Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Prototypes;
