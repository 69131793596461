import React, {Component} from 'react';
import fire from './fire';

class Feedback extends Component {
  state = {
    table: fire.database().ref('feedback'),
    rows: [],
    name:"",
    location:""
  }

  componentDidMount(){
    //We are using firebase to request a response from our table
    //The response is a higher order function object (don't ask - lol - I mean...do)
    //We then build a simple array of rows
    this.state.table.on('value', (response) => {
      let objects = response.val();
      let rows = [];
      for(let key in objects) {
        let row = objects[key];
        row.key = key;
        rows.push(row);
      }
      this.setState({rows});
    });
  }

  handleChange = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    this.setState({
      [name]: value
    });
  }

  add = (event) => {
    event.preventDefault();
    if(this.state.name){
      let row = {
        name: this.state.name,
        location: this.state.location
      }
      this.state.table.push(row);
      this.setState({name:"",location:""});
    }
  }

  delete = (row) => {
    this.state.table.child(row.key).remove();
  }

  render(){
    let rows = this.state.rows.map(row =>
      <li key={row.key} className="list-group-item">
        {row.name} <span className="text-muted">{row.location}</span>
        <button onClick={clickEvent => this.delete(row)} className="btn btn-danger float-right">
          <i className="fa fa-trash"></i>
        </button>
      </li>
    );

    return (
      <div className="row">
        <div className="col-12">
          <h2 className="pink m-3">Research Participants</h2>
          <form className="form-inline my-3">
            <input className="form-control" type="text"
              name="name"
              placeholder="Your name"
              onChange={this.handleChange}
              value={this.state.name}
            />
            <input className="form-control" type="text"
              name="location"
              placeholder="Current location"
              onChange={this.handleChange}
              value={this.state.location}
            />
            <button onClick={this.add} className="btn btn-success">
              <i className="fa fa-plus"></i>
            </button>
          </form>
          <ul className="list-group mb-3">
            {rows}
          </ul>
        </div>
      </div>
    );
  }
}

export default Feedback;
