import React, { Component } from 'react';
import './App.css';

class Contact extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12">
          <h2 className="pink m-3">Contact</h2>
          <div className="jumbotron mt-3">
            <h3>start<span className="pink">@</span>prettybrains.com</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
