import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import App from './App';

const BrainPass = React.lazy(() => import('./prototypes/brainpass/App'));
const BetterDates = React.lazy(() => import('./prototypes/betterdates/App'));
const Zach = React.lazy(() => import('./prototypes/zach/App'));
const Headshot = React.lazy(() => import('./prototypes/headshot/App'));

ReactDOM.render((
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/prototypes/betterdates" render={() => <BetterDates />} />
          <Route path="/prototypes/brainpass" render={() => <BrainPass />} />
          <Route path="/prototypes/zach" render={() => <Zach />} />
          <Route path="/prototypes/headshot" render={() => <Headshot />} />
          <Route component={App} />
        </Switch>
      </Suspense>
    </Router>
  ), document.getElementById('root'));
